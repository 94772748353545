import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { TableRow, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ApplicantQualification from "./ApplicantQualification";
import { IApplicant } from "../../infrastructure/api/applicants";
import { ApplicantHeader } from "./ApplicantHeader";
import { CommentComponent } from "../general/Comment";

interface IApplicantPDF {
  readonly applicant: IApplicant;
}

export class ApplicantPDF extends React.PureComponent<IApplicantPDF> {
  private createData(name: string, value: any) {
    return { name, value };
  }

  private createRows() {
    return [
      this.createData("Nummer", this.props.applicant.number),
      this.createData("E-Mail", this.props.applicant.email),
      this.createData("Alter", this.props.applicant.age),
      this.createData("Wohnort", this.props.applicant.address),
      this.createData("Berufserfahrung", this.props.applicant.experience),
      this.createData("Ausbildung", this.props.applicant.education),
      this.createData("Gehaltsvorstellung", this.props.applicant.salary),
      this.createData(
        "Frühester Beginn am",
        this.props.applicant.startAt
          ? new Date(this.props.applicant.startAt).toLocaleString("de-De", {
              month: "short",
              day: "numeric",
            })
          : "Kein Angabe"
      ),
      this.createData(
        "Letzer Kontakt am",
        this.props.applicant.lastContact
          ? new Date(this.props.applicant.lastContact).toLocaleString("de-De", {
              month: "short",
              day: "numeric",
            })
          : "Kein Kontakt"
      ),
    ];
  }

  render() {
    return (
      <Box sx={{ flexGrow: 1 }} p={4}>
        <ApplicantHeader
          name={this.props.applicant.name}
          campaign={this.props.applicant.campaign.data.attributes.name}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 650, maxWidth: 1000 }}
            aria-label="simple table"
          >
            <TableBody>
              {this.createRows().map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12} p={2}>
          <ApplicantQualification
            markdown={this.props.applicant.qualification}
          />
        </Grid>
        <Grid item xs={12} p={2}>
          <Typography>Kommentare:</Typography>
        {this.props.applicant.comments.data
          .slice(0)
          .reverse()
          .map((item) => {
            return (
              <CommentComponent
                id={item.id}
                author={item.attributes.author.data.attributes.name}
                updatedAt={item.attributes.updatedAt}
                text={item.attributes.text}
              />
            );
          })}
      </Grid>
      </Box>
    );
  }
}
