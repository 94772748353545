import * as React from "react";
import ReactMarkdown from "react-markdown";

interface IApplicantQualification {
  markdown: string;
}

export default function ApplicantQualification(props: IApplicantQualification) {
  return (
    <>
      <ReactMarkdown>{props.markdown}</ReactMarkdown>
    </>
  );
}
