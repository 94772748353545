import { createTheme, ThemeOptions } from "@mui/material";

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#d0cfcf",
      light: "#ffffff",
      dark: "#d0cfcf",
    },
    secondary: {
      main: "#FF154D",
      dark: "#37FFFF",
      light: "#F5FFFF",
    },
    background: {
      default: "#F2F2F2",
      paper: "#ffffff",
    },
    grey: {
      A100: "#F2F1F1",
      A200: "#DDDDDD",
      A400: "#B8B8B8",
      A700: "#323232",
    },
    text: {
      primary: "#323232",
      secondary: "#7f7f7f",
    },
    success: {
      main: "#46CF6E",
    },
    error: {
      main: "#FF4C5B",
    },
    warning: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Poppins",
    body1: {
      fontSize: 16,
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#323232",
    },
    body2: {
      fontSize: 16,
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#323232",
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#323232",
    },
    subtitle2: {
      fontSize: 16,
      color: "#323232",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontStyle: "alpha",
    },
    caption: {
      fontSize: 16,
      color: "#323232",
      fontWeight: "400",
      fontFamily: "Poppins",
      fontStyle: "alpha",
    },
    h1: {
      color: "#323232",
      fontWeight: "400",
      fontFamily: "Poppins",
    },
    h2: {
      color: "#323232",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontStyle: "alpha",
    },
    h3: {
      fontSize: 40,
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#323232",
    },
    h4: {
      fontSize: 24,
      color: "#323232",
      fontFamily: "Poppins",
      fontWeight: "600",
    },
    h5: {
      fontSize: 20,
      color: "#323232",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontStyle: "alpha",
    },
    h6: {
      fontSize: 16,
      color: "#323232",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontStyle: "alpha",
    },
  },
};

export const DefaultTheme = createTheme(theme);
