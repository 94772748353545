import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import LetterAvatar from "./LetterAvatar";
import { de } from "date-fns/locale";
import { formatDistance } from "date-fns";

interface CommentProps {
  readonly id: string;
  readonly author: string;
  readonly updatedAt: string;
  readonly text: string;
}

export const CommentComponent: React.FC<CommentProps> = ({
  id,
  author,
  text,
  updatedAt,
}) => {
  return (
    <Box key={id}>
                <Grid container spacing={1}>
                  <Grid item xs={1} style={{ paddingTop: 24 }}>
                    <LetterAvatar
                      name={author}
                      mr={0}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    style={{ paddingTop: 24, marginLeft: -16 }}
                  >
                    <Typography
                      display={"inline"}
                      sx={{ pr: 2, fontWeight: 600 }}
                    >
                      {author}
                    </Typography>
                    <Typography display={"inline"} color={"gray"} fontSize={14}>
                      {formatDistance(
                        new Date(updatedAt),
                        new Date(),
                        { addSuffix: true, locale: de }
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingTop: 0,
                      marginTop: -8,
                      marginLeft: -16,
                    }}
                  >
                    <Typography>{text}</Typography>
                  </Grid>
                </Grid>
              </Box>
  );
};
