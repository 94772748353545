import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInSide from "../pages/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import Board from "../pages/Board";
import ApplicantList from "../pages/List";
import Support from "../pages/Support";
import ChangePassword from "../pages/ChangePassword";
import ForgotPassword from "../pages/ForgotPassword";

export const Navigation = () => {
  return (
    <BrowserRouter>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Routes>
        <Route path="/login" element={<SignInSide />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Board />
            </ProtectedRoute>
          }
        />
        <Route
          path="/list"
          element={
            <ProtectedRoute>
              <ApplicantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <Support />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={"/"} replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};
