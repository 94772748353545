import * as React from "react";
import { Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import PeopleIcon from "@mui/icons-material/People";

export const ApplicantHeader = (props: { name: string; campaign: string }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"space-between"}
      p={1}
    >
      <Typography variant={"h3"}>{props.name}</Typography>
      <Chip icon={<PeopleIcon />} label={props.campaign} variant="outlined" />
    </Stack>
  );
};
