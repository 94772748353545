import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CustomButton } from "../general/CustomButton";
import { GeneralTextInput } from "../general/GeneralTextInput";
import { OnboardingPage } from "./OnboardingPage";
import { Formik } from "formik";
import * as Yup from "yup";

interface ILoginScreenProps {
  readonly error: boolean;
  readonly onLoginPress: (username: string, password: string) => void;
  readonly onForgotPress: () => void;
}

export const LoginScreen: React.FunctionComponent<ILoginScreenProps> = (
  props
) => {
  const loginSchema = Yup.object().shape({
    user: Yup.string().required("Benötigt").email("Email nicht korrekt"),
    password: Yup.string().required("Benötigt"),
  });
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, onShowPassword] = useState<boolean>(false);
  return (
    <OnboardingPage>
      <Formik
        initialValues={{
          user: "",
          password: "",
        }}
        validationSchema={loginSchema}
        validateOnMount={true}
        onSubmit={(values) => props.onLoginPress(values.user, values.password)}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
        }) => (
          <Box
            component={"form"}
            noValidate
            sx={{
              justifyContent: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  display: "flex",
                  flex: 0.5,
                  overflow: "hidden",
                  width: "90%",
                  height: 90,
                  pt: 5,
                },
              })}
            >
              <img
                height={60}
                src={
                  "https://matchandmake.de/wp-content/uploads/2022/04/matchandmake-logo-official.png"
                }
                alt={"logo"}
                loading={"lazy"}
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  width: "50%",
                },
                width: "85%",
              })}
            >
              <Typography
                variant={smallSize ? "h4" : "h3"}
                sx={{ color: "text.primary", fontFamily: "Poppins" }}
              >
                {"Willkommen bei matchandmake"}
              </Typography>
              <Typography
                variant={smallSize ? "h6" : "h5"}
                sx={{
                  color: "text.primary",
                  fontWeight: "400",
                  py: 2,
                  pb: 5,
                }}
              >
                {"Geben Sie bitte Ihre Zugangsdaten ein."}
              </Typography>
              <GeneralTextInput
                label={"Email"}
                placeholder={"E-Mail Adresse eingeben"}
                value={values.user}
                onBlur={handleBlur("user")}
                error={errors.user != undefined && touched.user}
                errorMessage={errors.user}
                onIconClick={() => onShowPassword(!showPassword)}
                showPassword={showPassword}
                onTextChange={handleChange("user")}
                type={"email"}
              />
              <GeneralTextInput
                isPassword
                label={"Passwort"}
                placeholder={"Bitte Passwort eingeben"}
                helperText={"Password"}
                value={values.password}
                error={errors.password != undefined && touched.password}
                errorMessage={errors.password}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                onIconClick={() => onShowPassword(!showPassword)}
                showPassword={showPassword}
                onBlur={handleBlur("password")}
                onTextChange={handleChange("password")}
                onSubmit={() => handleSubmit()}
              />
              {props.error && (
                <FormHelperText sx={{ pb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "error.main",
                      position: "absolute",
                    }}
                  >
                    {"Email/Passwort Kombination nicht gefunden."}
                  </Typography>
                </FormHelperText>
              )}
              {smallSize && (
                <Box flexGrow={1} display={"flex"} justifyContent={"center"}>
                  <CustomButton
                    disabled={!isValid}
                    text={"Login"}
                    onClick={() => handleSubmit()}
                  />
                </Box>
              )}
              <Button
                onClick={() => props.onForgotPress()}
                sx={{
                  px: 0,
                  pt: 1,
                  textTransform: "none",
                  color: "#000",
                  fontSize: 14,
                  fontFamily: "Poppins",
                }}
              >
                {"Passwort vergessen"}
              </Button>
              <Box
                sx={{
                  ml: smallSize ? -2 : 0,
                  pt: smallSize ? 1 : 5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: smallSize ? "column" : "row",
                }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography sx={{ fontSize: 14, fontFamily: "Poppins" }}>
                    Noch keine Kunde?
                  </Typography>
                  <Link
                    target="_blank"
                    href={"https://calendly.com/matchandmake-lukas/30min"}
                  >
                    <Typography color={"secondary"}>
                      Hier geht es zur Terminvereinbarung
                    </Typography>
                  </Link>
                </Box>
                {!smallSize && (
                  <Box
                    flexGrow={1}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <CustomButton
                      disabled={!isValid}
                      text={"Anmelden"}
                      onClick={() => handleSubmit()}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 0.5,
                justifySelf: "flex-end",
                alignSelf: "flex-end",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignSelf: "flex-end",
                  flexDirection: "column",
                  pb: 5,
                }}
              >
                <Divider
                  color={"#979797"}
                  sx={{ width: "75%", alignSelf: "flex-end", mr: 10 }}
                />
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                      flexDirection: "column",
                    },
                  })}
                  pt={2}
                  pr={5}
                  pl={5}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Nutzungsbedingungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/datenschutz/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Datenschutzbestimmungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Impressum
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    </OnboardingPage>
  );
};
