import * as React from "react";
import { useState } from "react";
import { IComment } from "../../infrastructure/api/applicants";
import { Grid, TextField, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LetterAvatar from "../general/LetterAvatar";
import SendIcon from '@mui/icons-material/Send';
import useToken from "../../hooks/useToken";
import { useTheme } from '@mui/material/styles';
import { CommentComponent } from "../general/Comment";

interface IApplicantCommentsSection {
  handleCommentSubmit(comment: string): Promise<void>;

  comments: IComment[];
}

export default function ApplicantCommentsSection(
  props: IApplicantCommentsSection
) {
  const [comment, setComment] = useState("");
  const { user } = useToken();
  const theme = useTheme();


  return (
    <>
      <Grid item xs={12}>
        <TextField
          variant={"standard"}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <LetterAvatar name={user ? user.name : "Not found"} mr={3} />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    if (comment !== "") {
                      props.handleCommentSubmit(comment);
                      setComment("");
                    }
                  }}
                  style={{ color: comment ? theme.palette.secondary.main : 'inherit' }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          placeholder={"Kommentar eingeben..."}
          id="comment"
          name="Kommentar"
          label=""
          onKeyDown={(e) => {
            if (e.key === "Enter" && comment !== "") {
              props.handleCommentSubmit(comment);
              setComment("");
            }
          }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {props.comments
          .slice(0)
          .reverse()
          .map((item) => {
            return (
              <CommentComponent
                id={item.id}
                author={item.attributes.author.data.attributes.name}
                updatedAt={item.attributes.updatedAt}
                text={item.attributes.text}
              />
            );
          })}
      </Grid>
    </>
  );
}
