import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { getInitials } from "../../infrastructure/getInitials";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(
  name: string,
  mr: number,
  width?: number,
  height?: number,
  fontsize?: number,
  fontWeight?: number
) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width ? width : 32,
      height: height ? height : 32,
      fontSize: fontsize ? fontsize : 14,
      fontWeight: fontWeight ? fontWeight : 600,
      mr,
    },
    children: getInitials(name),
  };
}

export default function LetterAvatar({
  name,
  mr,
  width,
  height,
  fontSize,
  fontWeight,
}: {
  name: string;
  mr: number;
  width?: number;
  height?: number;
  fontSize?: number;
  fontWeight?: number;
}) {
  return (
    <Avatar {...stringAvatar(name, mr, width, height, fontSize, fontWeight)} />
  );
}
