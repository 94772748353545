import * as React from "react";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";

interface ListItemButtonProps extends MuiAppBarProps {
  active?: boolean;
}

const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})<ListItemButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? "#ffe7ed" : "white",
  borderBottomRightRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const MainListItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <React.Fragment>
      <ListItemButton
        active={location.pathname === "/"}
        onClick={() => navigate("/")}
      >
        <ListItemIcon>
          <DashboardIcon
            color={location.pathname === "/" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Board" />
      </ListItemButton>
      <ListItemButton
        active={location.pathname === "/list"}
        onClick={() => navigate("/list")}
      >
        <ListItemIcon>
          <ListIcon
            color={location.pathname === "/list" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Liste" />
      </ListItemButton>
      <ListItemButton
        active={location.pathname === "/support"}
        onClick={() => navigate("/support")}
      >
        <ListItemIcon>
          <HelpCenterIcon
            color={location.pathname === "/support" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Einstellungen" />
    </ListItemButton>
    {/*
      <ListItemButton>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Unternehmen" />
    </ListItemButton>
      */}
  </React.Fragment>
);
