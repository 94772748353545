import * as React from "react";
import { Box, Typography } from "@mui/material";
import { InlineWidget } from "react-calendly";

export default function Support() {
  return (
    <Box>
      <Typography fontSize={24} fontWeight={600} sx={{ pb: 2, pl: 1 }}>
        Support
      </Typography>
      <InlineWidget
        url={
          "https://calendly.com/matchandmake-lukas/30min?hide_gdpr_banner=1&primary_color=ff164c%22"
        }
      />
    </Box>
  );
}
