import * as React from "react";
import { IAttachment } from "../../infrastructure/api/applicants";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FilePlaceholder from "../general/FilePlaceholder";
import { UploadFile } from "@mui/icons-material";

interface IApplicantQualification {
  files: IAttachment[];
  handleUploadFile(file: File): Promise<void>;
}

export default function ApplicantFiles(props: IApplicantQualification) {
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      props.handleUploadFile(files[0])
    }
  }

  if (!props.files) {
    return (
      <Grid>
        <Typography>Keine Datei vorhanden</Typography>
        <Button            
            component="label"
            startIcon={<UploadFile />}
            color={"secondary"}
            sx={{ mt: 2 }}
          >
            Datei hochladen
            <input
              type="file"
              hidden
              onChange={handleUploadClick}
            />
        </Button>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      {props.files.map((file) => {
        return (
          <Grid item xs={3}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 1,
                  alignItems: "center",
                }}
              >
                <FilePlaceholder />
              </Box>
              <CardContent>
                <Typography
                  style={{ wordWrap: "break-word" }}
                  fontSize={12}
                  pb={2}
                >
                  {file.attributes.name}
                </Typography>
                <Link
                  href={file.attributes.url}
                  color="secondary"
                  underline="hover"
                  target={"_blank"}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DownloadIcon fontSize={"small"} />
                    <Typography color={"secondary"}>Download</Typography>
                  </Stack>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
      <Grid container justifyContent="flex-end">
              <Button            
                  component="label"
                  startIcon={<UploadFile />}
                  color={"secondary"}
                  sx={{ mt: 2 }}
                >
                  Datei hochladen
                  <input
                    type="file"
                    hidden
                    onChange={handleUploadClick}
                  />
              </Button>
        </Grid>
    </Grid>
  );
}
