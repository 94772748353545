import React from "react";

export default function FilePlaceholder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="60"
      data-name="Layer 1"
      viewBox="0 0 1008.92 607.45"
    >
      <path
        fill="#e6e6e6"
        d="M1008.92 474.03a15.34 15.34 0 01-15.26 15.42h-671.4A15.34 15.34 0 01307 474.03V15.42A15.34 15.34 0 01322.26 0h671.4a15.34 15.34 0 0115.26 15.42v458.61z"
      ></path>
      <path
        fill="#fff"
        d="M1001 466.52a14.91 14.91 0 01-14.91 14.91h-655.8a14.91 14.91 0 01-14.91-14.91V23.14a14.91 14.91 0 0114.91-14.91h655.83A14.91 14.91 0 011001 23.14z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M445.97 173.755h616.22a8.85 8.85 0 018.85 8.85v415.28a8.85 8.85 0 01-8.85 8.85H445.97a8.85 8.85 0 01-8.85-8.85v-415.28a8.85 8.85 0 018.85-8.85z"
        data-name="b9c54412-061c-4155-b289-fb4c23e4a64e"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M358.23 37.44h599.95a8.85 8.85 0 018.85 8.85v393.12a8.85 8.85 0 01-8.85 8.85H358.23a8.85 8.85 0 01-8.85-8.85V46.29a8.85 8.85 0 018.85-8.85z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M853.92 529.03a15.34 15.34 0 01-15.26 15.42h-671.4A15.34 15.34 0 01152 529.03V70.42A15.34 15.34 0 01167.26 55h671.4a15.34 15.34 0 0115.26 15.42v458.61z"
      ></path>
      <path
        fill="#fff"
        d="M846 521.52a14.91 14.91 0 01-14.91 14.91h-655.8a14.91 14.91 0 01-14.91-14.91V78.14a14.91 14.91 0 0114.91-14.91h655.83A14.91 14.91 0 01846 78.14z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M290.97 228.755h616.22a8.85 8.85 0 018.85 8.85v415.28a8.85 8.85 0 01-8.85 8.85H290.97a8.85 8.85 0 01-8.85-8.85v-415.28a8.85 8.85 0 018.85-8.85z"
        data-name="a7242049-80b4-49e1-bd08-67354734c824"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M203.23 92.44h599.95a8.85 8.85 0 018.85 8.85v393.12a8.85 8.85 0 01-8.85 8.85H203.23a8.85 8.85 0 01-8.85-8.85V101.29a8.85 8.85 0 018.85-8.85z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M701.92 592.03a15.34 15.34 0 01-15.26 15.42H15.26A15.34 15.34 0 010 592.03V133.42A15.34 15.34 0 0115.26 118h671.4a15.34 15.34 0 0115.26 15.42v458.61z"
      ></path>
      <path
        fill="#fff"
        d="M694 584.52a14.91 14.91 0 01-14.91 14.91H23.29a14.91 14.91 0 01-14.91-14.91V141.14a14.91 14.91 0 0114.91-14.91h655.83A14.91 14.91 0 01694 141.14z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M138.97 291.755h616.22a8.85 8.85 0 018.85 8.85v415.28a8.85 8.85 0 01-8.85 8.85H138.97a8.85 8.85 0 01-8.85-8.85v-415.28a8.85 8.85 0 018.85-8.85z"
        data-name="b7a050eb-0cb2-44a8-b642-35d340850eff"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M51.23 155.44h599.95a8.85 8.85 0 018.85 8.85v393.12a8.85 8.85 0 01-8.85 8.85H51.23a8.85 8.85 0 01-8.85-8.85V164.29a8.85 8.85 0 018.85-8.85z"
      ></path>
      <path
        fill="#e5e5e5"
        d="M494.602 240.599v272a6.005 6.005 0 01-6 6h-285a6.005 6.005 0 01-6-6v-304a6.005 6.005 0 016-6h249z"
      ></path>
      <path
        fill="#fff"
        d="M343.578 415.072a3.755 3.755 0 000 7.509h99.004a3.755 3.755 0 10.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M343.578 450.291a3.755 3.755 0 000 7.509h196.004a3.755 3.755 0 00.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M343.578 485.51a3.755 3.755 0 000 7.509h196.004a3.755 3.755 0 10.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M343.578 520.73a3.755 3.755 0 000 7.508h196.004a3.755 3.755 0 10.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M343.578 555.948a3.755 3.755 0 000 7.509h196.004a3.755 3.755 0 10.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path
        fill="#fff"
        d="M343.578 591.167a3.755 3.755 0 000 7.509h196.004a3.755 3.755 0 10.124-7.509h-.124z"
        data-name="Path 40"
        transform="translate(-95.54 -146.275)"
      ></path>
      <path fill="#e5e5e5" d="M494.602 240.599h-38a4 4 0 01-4-4v-34z"></path>
      <path d="M494.602 240.599h-38a4 4 0 01-4-4v-34z" opacity="0.2"></path>
    </svg>
  );
}
